import { createAction, props } from '@ngrx/store';
import { IProgramVM } from '../../models/view-models/programs/program.interface';
import { RouterOutput } from '@roadrunner/shared/util-trpc';

export type IAProgram =
  RouterOutput['program']['getProgramList']['program'];
  export type RiskType =
  RouterOutput['program']['getProgramList']['riskType'];

export const getProgramList = createAction('[Login] User Program list');
export const getProgramListSuccess = createAction(
  '[Login] User Get Program list Success',
  props<{ programs: IAProgram; riskTypes: RiskType }>()
);
export const getProgramListFail = createAction(
  '[Login] User Get Program list Fail',
  props<{ error: unknown }>()
);

export const chooseProgram = createAction(
  '[Login-choose-program] User Choose Program',
  props<{ program: IProgramVM }>()
);
