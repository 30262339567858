import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ProgramsGuard } from '@roadrunner/dcu/data-access-program';
import { environment } from 'environments/environment';
import { ChooseProgramComponent } from './pages/sessions/choose-program/choose-program.component';
import { NotFoundComponent } from './pages/sessions/not-found/not-found.component';
import { DashboardLayoutComponent } from './shared/components/layouts/dashboard-layout/dashboard-layout.component';
import { BucketGuard } from './shared/guards/bucket.guard';
import { ChooseProgramGuard } from './shared/guards/choose-program.guard';
import { SelectedProgramGuard } from './shared/guards/selected-program.guard';

const msalGuard = environment.requireAuth ? [MsalGuard] : [];

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivateChild: [...msalGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
        data: { title: 'Home', requiresProgram: false },
      },
    ],
  },
  {
    path: '',
    canDeactivate: [ProgramsGuard],
    children: [
      {
        path: 'dealers',
        component: DashboardLayoutComponent,
        canActivateChild: [...msalGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              // TODO: Temporarily disable module boundaries to make dealers work
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@roadrunner/dcu/feature-entities').then(
                (m) => m.DcuFeatureEntitiesModule
              ),
          },
          {
            path: ':dealerId',
            loadChildren: () =>
              // TODO: Temporarily disable module boundaries to make dealers work
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@roadrunner/dcu/feature-entity').then(
                (m) => m.DcuFeatureEntityModule
              ),
          },
        ],
      },
      {
        path: 'enrollments',
        component: DashboardLayoutComponent,
        canActivateChild: [...msalGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              // TODO: Temporarily disable module boundaries to make program product enrollments work
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@roadrunner/dcu/feature-program-product-enrollment').then(
                (m) => m.DcuFeatureProgramProductEnrollmentModule
              ),
          },
        ],
      },
      {
        path: 'override-payees',
        component: DashboardLayoutComponent,
        canActivateChild: [...msalGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              // TODO: Temporarily disable module boundaries to make program product enrollments work
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@roadrunner/dcu/feature-commissions-payees').then(
                (m) => m.DcuFeatureCommissionsPayeesModule
              ),
          },
        ],
      },
      {
        path: 'ncb-percentages',
        component: DashboardLayoutComponent,
        canActivateChild: [...msalGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              // TODO: Temporarily disable module boundaries to make program product enrollments work
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@roadrunner/dcu/feature-ncb-percentage').then(
                (m) => m.DcuFeatureNcbPercentageModule
              ),
          },
        ],
      },
      {
        path: 'licensing-rules',
        component: DashboardLayoutComponent,
        canActivateChild: [...msalGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              // TODO: Temporarily disable module boundaries to make program product enrollments work
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@roadrunner/dcu/feature-licensing').then(
                (m) => m.DcuFeatureLicensingModule
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'dashboard/home',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'dashboard',
    pathMatch: 'prefix',
    redirectTo: 'rating',
  },
  {
    path: 'rating',
    component: DashboardLayoutComponent,
    children: [
      {
        canActivate: [ChooseProgramGuard, ...msalGuard],
        path: 'choose-program',
        component: ChooseProgramComponent,
        data: { title: 'Choose Program' },
      },
      {
        path: 'products',
        canActivate: [...msalGuard, SelectedProgramGuard],
        loadChildren: () =>
          import('./pages/products/products.module').then(
            (m) => m.ProductsModule
          ),
        data: { title: 'Products', requiresProgram: true },
      },
      {
        path: 'bucket/:id',
        pathMatch: 'full',
        redirectTo: 'rates/bucket/:id',
      },
      {
        path: 'rates/bucket/:id',
        canActivate: [...msalGuard, BucketGuard, SelectedProgramGuard],
        loadChildren: () =>
          import('./pages/bucket/bucket.module').then((m) => m.BucketModule),
        data: { title: 'Bucket', requiresProgram: true },
      },
      {
        path: 'rates',
        canActivate: [...msalGuard, SelectedProgramGuard],
        loadChildren: () =>
          import('./pages/rates/rates.module').then((m) => m.RatesModule),
        data: { title: 'Rates', requiresProgram: true },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: 'classing',
    component: DashboardLayoutComponent,
    canActivateChild: [...msalGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
          import('@roadrunner/classing').then(
            (module) => module.ClassingModule
          ),
      },
    ],
  },
  {
    path: 'file-import-export',
    component: DashboardLayoutComponent,
    canActivateChild: [...msalGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
          import('@roadrunner/file-import-export').then(
            (module) => module.FileImportExportModule
          ),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Not Found' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
