import { createReducer, on } from '@ngrx/store';
import { ProgramActions } from '@roadrunner/rating-utility/data-access-program';
import { RiskType } from '../../models/view-models/risk-type';
import * as UserActions from './user.actions';
import { RouterOutput } from '@roadrunner/shared/util-trpc';

export const userStateFeatureKey = 'userState';

export type IAProgram =
  RouterOutput['program']['getProgramList']['program'][number];
export interface State {
  programs: IAProgram[] | null;
  selectedProgram: IAProgram | null;
  riskTypes: RiskType[];
}

export const initialState: State = {
  selectedProgram: null,
  programs: null,
  riskTypes: [],
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.getProgramListSuccess, (state, action) => {
    return {
      ...state,
      programs: action.programs,
      riskTypes: action.riskTypes,
    };
  }),
  on(
    ProgramActions.productLoaded,
    ProgramActions.productRatesLoaded,
    (state, action) => {
      if (state.selectedProgram?.id === action.programId) {
        return state;
      }
      return {
        ...state,
        selectedProgram:
          state.programs?.find((p) => p.id === action.programId) ?? null,
      };
    }
  ),
  on(
    UserActions.chooseProgram,
    ProgramActions.addProgramSuccess,
    (state, action): State => {
      return {
        ...state,
        selectedProgram: {
          id: action.program.id,
          name: action.program.name,
          agentCode: action.program.agentCode,
          canAddRemoveBuckets: action.program.canAddRemoveBuckets,
          cms: action.program.cms,
        },
      };
    }
  ),
  on(ProgramActions.addProgramSuccess, (state, action) => {
    return {
      ...state,
      programs: [
        ...(state.programs ?? []),
        {
          id: action.program.id,
          name: action.program.name,
          agentCode: action.program.agentCode,
          canAddRemoveBuckets: action.program.canAddRemoveBuckets,
          cms: action.program.cms,
        },
      ],
    };
  }),
  on(ProgramActions.editProgramSuccess, (state, action): State => {
    if (!state.selectedProgram) {
      throw new Error(
        'Unable to update selected program for empty selected program state'
      );
    }

    return {
      ...state,
      selectedProgram: {
        ...state.selectedProgram,
        cms: action.program.cms,
      },
    };
  }),
  on(ProgramActions.editProgramSuccess, (state, action) => {
    if (!state.programs || state.programs.length === 0) {
      throw new Error('Unable to update program for empty programs state');
    }

    const programs = state.programs.map((program) => {
      if (action.program.id === program.id) {
        return {
          ...program,
          cms: action.program.cms,
        };
      }
      return program;
    });

    return {
      ...state,
      programs,
    };
  })
);
