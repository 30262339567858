import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { uniqueValidator } from '@roadrunner/shared/util-forms';
import { map, Observable } from 'rxjs';

import { AddProductDialogData } from './add-product-dialog-data';
import { AddProductDialogResult } from './add-product-dialog-result';

@Component({
  selector: 'rating-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductComponent {
  form: UntypedFormGroup;

  productTypeTriggerText$: Observable<string | null>;

  riskTypeTriggerText$: Observable<string | null>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddProductDialogData,
    private dialogRef: MatDialogRef<
      AddProductDialogData,
      AddProductDialogResult
    >
  ) {
    const riskType = new UntypedFormControl(null, Validators.required);
    const productTypeId = new UntypedFormControl(null, Validators.required);
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      code: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        uniqueValidator(data.productCodes),
      ]),
      description: new UntypedFormControl(null),
      riskType,
      productTypeId,
    });
    this.productTypeTriggerText$ = productTypeId.valueChanges.pipe(
      map((productTypeId: number | null) => {
        const productType = this.data.productTypes.find(
          (pt) => pt.id === productTypeId
        );
        if (!productType) {
          return null;
        }
        return `${productType.description} - ${productType.name}`;
      })
    );
    this.riskTypeTriggerText$ = riskType.valueChanges.pipe(
      map((riskTypeCode: string | null) => {
        const riskType = this.data.riskTypes.find(
          (rt) => rt.riskTypeCode === riskTypeCode
        );
        if (!riskType) {
          return null;
        }
        return `${riskType.riskTypeDescription} - ${riskType.riskTypeCode}`;
      })
    );
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        code: this.form.value.code,
        name: this.form.value.name,
        description: this.form.value.description,
        riskType: this.form.value.riskType,
        productTypeId: this.form.value.productTypeId,
      });
    }
  }
}
