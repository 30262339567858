import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProgramVM } from '../../models/view-models/programs/program.interface';
import { State, userStateFeatureKey } from './user.reducers';
import { RouterOutput } from '@roadrunner/shared/util-trpc';

const selectUserState = createFeatureSelector<State>(userStateFeatureKey);

export type IAProgram =RouterOutput['program']['getProgramList']['program'][number];
  

export const selectProgramList = createSelector(
  selectUserState,
  (state: State) =>
    state.programs
      ?.map((program: IAProgram): IProgramVM => {
        return {
          id: program.id,
          name: program.name,
          agentCode: program.agentCode,
          canAddRemoveBuckets: program.canAddRemoveBuckets,
          cms: program.cms,
        };
      })
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      ) ?? null
);
export const selectChosenProgram = createSelector(
  selectUserState,
  (state: State): IProgramVM | null =>
    state.selectedProgram
      ? {
          id: state.selectedProgram.id,
          name: state.selectedProgram.name,
          agentCode: state.selectedProgram.agentCode,
          canAddRemoveBuckets: state.selectedProgram.canAddRemoveBuckets,
          cms: state.selectedProgram.cms,
        }
      : null
);
export const selectChosenProgramId = createSelector(
  selectUserState,
  (state: State): number | null => state.selectedProgram?.id ?? null
);

export const selectChosenProgramName = createSelector(
  selectChosenProgram,
  (program) => program?.name
);
export const selectCanAddRemoveBuckets = createSelector(
  selectChosenProgram,
  (program) => {
    return program != null && program.canAddRemoveBuckets;
  }
);
export const selectChosenProgramIsCms = createSelector(
  selectChosenProgram,
  (program) => program?.cms ?? false
);

export const selectRiskTypes = createSelector(
  selectUserState,
  (state) => state.riskTypes
);

export const selectHasChosenProgram = createSelector(
  selectChosenProgram,
  (p) => p != null
);
